// Storage for topics that can be broadcast
// or listened to
const __topics = {};

// A topic identifier
let subUid = -1;

const getTopicSubscribers = topic => __topics[topic];
const addSubscribersToTopic = topic => (__topics[topic] = []);
const getNextToken = () => (++subUid).toString();
const addNewSubscriber = (subscribers, callback) => {
  const token = getNextToken();
  subscribers.push({
    token,
    callback
  });
  return token;
};
const getTopics = () => Object.values(__topics);

// Publish or broadcast events of interest
// with a specific topic name and arguments
// such as the data to pass along
export const publish = (topic, payload, conf = { sync: false }) => {
  const subscribers = getTopicSubscribers(topic);
  if (!subscribers) {
    return false;
  }

  if (conf.sync) {
    // This way the main thread BLOCKS until all subscribers have been executed
    subscribers.forEach(({ callback }) => callback(payload));
  } else {
    // Default behaviour
    // Wrap each callback in setTimeout 0, so each suscriber callback gets is own event (in the event queue)
    // This way the main thread does NOT block, each callback wait fot the next event loop tick
    subscribers.forEach(({ callback }) =>  setTimeout(callback, 0 , payload));
  }
  return true;
};

// Subscribe to events of interest
// with a specific topic name and a
// callback function, to be executed
// when the topic/event is observed
export const subscribe = (topic, callback) => {
  if (typeof callback !== "function") throw "callback should be a function";
  let topicSubscribers = getTopicSubscribers(topic);
  if (!topicSubscribers) {
    topicSubscribers = addSubscribersToTopic(topic);
  }
  return addNewSubscriber(topicSubscribers, callback);
};

// Unsubscribe from a specific
// topic, based on a tokenized reference
// to the subscription
export const unsubscribe = token => {
  const topics = getTopics();
  let result = false;
  topics.forEach(topic => {
    const index = topic.findIndex(({ token: subToken }) => token === subToken);
    if (index !== -1) {
      topic = topic.splice(index, 1);
      result = true;
      return true;
    }
  });

  return result;
};
